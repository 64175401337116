<template>
     <vue-html2pdf v-if="invoice"
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1500"
        :filename="invoice.values['c-zAunCOV3n5']"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        ref="html2Pdf"
        pdf-content-width="100%"
        height="900px"
    >
        <section slot="pdf-content">
 <div class="invoice-box">
   <table cellpadding="0" cellspacing="0">
    <tr class="top">
     <td colspan="4">
      <table>
       <tr>
        <td class="title">
         <img src="../assets/logo.jpg" style="width: 100%; max-width: 200px" />
        </td>

        <td>
         Facture #: {{invoice.values['c-zAunCOV3n5']}}<br />
         Date d'émission: {{invoice.createdAt |dateFormat}}<br />
         Dûe au :  {{invoice.createdAt |dateFormat}}
        </td>
       </tr>
      </table>
     </td>
    </tr>

    <tr class="information">
     <td colspan="4">
      <table>
       <tr>
        <td>
         Les Galipes De La Varenne<br />
         Montrichard <br />
         69460 VAUX-EN-BEAUJOLAIS
        </td>

        <td>
         {{invoice.values['c-SE-bGncDlh']}}
        </td>
       </tr>
      </table>
     </td>
    </tr>

    <tr class="heading">
      <td>Produits</td>
      <td class="right">Quantité</td>
      <td class="right">PU</td>
      <td class="right">Sous total</td>
    </tr>

    <tr class="item " v-for="(row,index) in rows" v-bind:key="index">
     <td>{{row.values['c-ppsgBFg0-P'] | withoutEmoji}} de {{row.values['c-iqH3jcLgwT']}}</td>
     <td class="right">{{row.values['c-BtbTCIBqyE']}}</td>
     <td class="right">{{row.values['c-C3eLn8ziaf']}}</td>
     <td class="right">{{row.values['c-E2NuGt1Hqq']}}</td>
    </tr>

    <tr class="total">
      <td  colspan="3" class="right">Total <span v-if="invoice.values['c--iYjFPwVWb']">HT</span> :</td>
      <td>{{invoice.values['c-n0Q9-njrQI']}}</td>
    </tr>
    <tr v-if="invoice.values['c--iYjFPwVWb']">
      <td  colspan="3" class="right">TVA :</td>
      <td>{{invoice.values['c-Z-vtx43mxb']}}</td>
    </tr>
    <tr v-if="invoice.values['c--iYjFPwVWb']">
      <td  colspan="3" class="right">Total TTC :</td>
     <td>{{invoice.values['c-n4o4zRCxo1']}}</td>
    </tr>
   </table>
   <div class="footer">SARL - Capital social 2200 € - TVA : FR81822425872 - SIRET : 82242587200014 - Villefranche sur Saone-Tarare B 822 425 872</div>
  </div>
        </section>
    </vue-html2pdf>
</template>

<script>
import { Coda } from 'coda-js';
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'

export default {
  name: 'HelloWorld',
  components:{
    VueHtml2pdf
  },
  created(){
      console.log(this.$route.params.num)
      const coda = new Coda('a8d946e4-d36e-4b97-a1be-f00be072496e'); 
      coda.getTable('LCtlFa1c5T',"grid-WM8QUCsZtf").then(table=>{
        table.listRows( {query: "c-zAunCOV3n5:\""+this.$route.params.num+"\"",visibleOnly:false}).then(rows=>{
          this.invoice = rows[0];
          coda.getTable('LCtlFa1c5T',"grid-0P4PUixBt6").then(table=>{
            table.listRows({query:"c-AZ7_AtypwP:\""+this.$route.params.num+"\"",visibleOnly:false}).then(rows=>{
              this.rows=rows;
            });
          });
        })
      })
    },
  data: function(){ 
    return {
      invoice:false,
      rows:false
    }
    },
    methods: {
      createPDF() {
        this.$refs.html2Pdf.generatePdf()
      }
    },
    computed: {
    },
    filters: {
      withoutEmoji: function (value) {
        return value.replace("📦","")
      },
      dateFormat: function (date) {
        return moment(date).format("DD/MM/YYYY")
      }
    },
    watch: {
      rows: function () {
        if(this.rows)
          this.createPDF();
          window.close();
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
